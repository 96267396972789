<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.tenant">
            {{ getErrors.tenant }}
          </p>

          <h1>
            {{ $t("Which country do you live in?") }}
          </h1>
          <p class="fw-medium text-gray">
            {{ $t("Based on your country, we can fine-tune the checks in a later phase") }}
          </p>

          <Multiselect
            name="country"
            v-model="form.country"
            deselect-label="Can't remove this value"
            label="text"
            track-by="value"
            :placeholder="$t('Select country')"
            :options="countries"
            :searchable="true"
            :allow-empty="false"
            :multiple="false"
            :loading="countryLoading"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.text }}
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.text }}</span>
              </div>
            </template>
          </Multiselect>

          <div class="text-center mt-3">
            <small class="text-danger" v-if="error">{{ error }}</small>
          </div>

          <div class="w-100 mt-4">
            <button
              class="btn btn-primary btn-block"
              :disabled="getLoadings.tenant"
              :class="{ disabled: !form.country }"
              @click="submit"
            >
              {{ $t("Next step") }}
              <b-spinner
                v-if="getLoadings.tenant"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto">
        <RightPoints active="2" />
      </div>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import Multiselect from "vue-multiselect";
import RightPoints from "./RightPoints.vue";

export default {
  data() {
    return {
      countries: [],
      institutes: [],
      instituteLoading: false,
      countryLoading: false,
      submitting: false,
      form: {
        country: {
          text: "Netherlands",
          value: "NL",
        },
      },
      error: "",
    };
  },
  components: {
    AuthLayoutContent,
    Multiselect,
    RightPoints,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  async created() {
    console.log("this.$route.params.step", this.$route.params.tenant);
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }
    this.getCountries();
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    async getCountries() {
      this.countryLoading = true;
      const { data } = await window.axios.get("/countries");
      this.countries = data.map((c) => ({ value: c.code, text: c.name }));
      this.countryLoading = false;
    },
    async submit() {
      await this.updateTenant({ country: this.form.country.value });
      this.$router.push({
        name: "ExpatStep4",
        params: { tenant_id: this.tenant.id },
      });
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
