<template>
  <ul v-if="tenant">
    <li class="mb-4" :class="{ active: active == 1 }">
      <i class="fa-regular fa-circle-check mr-1"></i>
      {{ $t("Register phonenumber") }}
    </li>
    <li
      class="my-4"
      :class="{ active: active == 2 }"
      v-if="tenant.expat && tenant.profession == 'entrepreneur'"
    >
      <i class="fa-regular fa-circle-check mr-1"></i>
      {{ $t("Upload documents") }}
    </li>
    <li class="my-4" :class="{ active: active == 2 }" v-if="!tenant.expat">
      <i class="fa-regular fa-circle-check mr-1"></i>
      {{ $t("Select your country") }}
    </li>
    <li class="my-4" :class="{ active: active == 3 }" v-if="!tenant.expat">
      <i class="fa-regular fa-circle-check mr-1"></i>
      {{ $t("Add personal details") }}
    </li>
    <li class="my-4" :class="{ active: active == 4 }">
      <i class="fa-regular fa-circle-check mr-1"></i>
      {{ $t("Verify indentity") }}
    </li>
    <li class="my-4" :class="{ active: active == 5 }">
      <i class="fa-regular fa-circle-check mr-1"></i>
      {{ $t("Check fiinancial data") }}
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["active"],
  computed: {
    ...mapGetters("tenant", ["tenant"]),
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
li {
  color: $text-gray-inactive;

  &.active {
    i {
      color: $primary;
    }
    color: $black;
  }

  i {
    font-size: 18px;
  }
}
</style>
